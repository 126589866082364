import React from 'react';
import PropTypes from 'prop-types';
import List from '../List';
import Button from '../Button';
import './InfoSection.scss';

const InfoSection = ({
  imageFile,
  color,
  headerText,
  caption,
  altText,
  listItems
}) => (
  <section key={ imageFile } className={`InfoSection bg-${color}`}>
    <h2 className="InfoSection__header">
      { headerText }
    </h2>
    <div className="InfoSection__wrapper">
      <div className="InfoSection__left">
        <figure className="InfoSection__figure">
          <figcaption className="InfoSection__caption">
            { caption }
          </figcaption>
          <img
            src={ imageFile }
            alt={ altText }
          />
        </figure>
        <div className="InfoSection__button-wrapper">
          <Button color="gold" link="/contact">Request a Demo</Button>
        </div>
      </div>
      <div className="InfoSection__right">
        <List listItems={ listItems } />
      </div>
    </div>
  </section>
)

InfoSection.propTypes = {
  imageFile: PropTypes.string,
  altText: PropTypes.string,
  caption: PropTypes.string,
  headerText: PropTypes.string,
  bullet: PropTypes.array,
  color: PropTypes.oneOf(['0', '1', '2', '3', '4']),
}

InfoSection.defaultProps = {
  imageFile: '',
  altText: '',
  caption: '',
  headerText: '',
  listItems: [],
  color: '1',
}

export default InfoSection;
