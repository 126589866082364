import React from 'react';
import PropTypes from 'prop-types';
import './List.scss';

const List = ({listItems}) => (
  <ul className="List">
    {listItems.map(listItem => (
      <li key={ listItem.bullet } className="List__item">
        { listItem.bullet }
      </li>
    ))}
  </ul>
)

List.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.shape({
    bullet: PropTypes.string,
  })),
}

List.defaultProps = {
  listItems: [],
}

export default List;
