import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import Helmet from 'react-helmet';
import InfoSection from '../../components/InfoSection';
import PageTitle from '../../components/PageTitle';
import Cta from '../../components/Cta';
import './HowItWorks.scss';

export default ({ data }) => {
  const { frontmatter } = data.pageMarkdown
  const { frontmatter: cta } = data.ctaMarkdown
  const { siteMetadata } = data.site

  return (
    <Layout>
      <Helmet title={`${frontmatter.title} | ${siteMetadata.title}`}>
        <meta name="description" content={ frontmatter.description } />
      </Helmet>
      <PageTitle metadata={siteMetadata} className="HowItWorks__title-wrapper">
        <h1 className="HowItWorks__title">{frontmatter.title}</h1>
        <h3 className="HowItWorks__subtitle">{frontmatter.subtitle}</h3>
      </PageTitle>
      <div id="main-content">
        { frontmatter.section.map(item => (
          <InfoSection
            key={ item.left.img.image_file }
            imageFile={ item.left.img.image_file }
            altText={ item.left.img.alt_text }
            caption={ item.left.caption }
            headerText={ item.right.header_text }
            listItems={ item.right.bullet }
            color={ item.color }
          />
        ))}
      </div>
      <Cta
        title={ cta.title }
        buttonLink={ cta.button.link }
        buttonText={ cta.button.text }
      />
    </Layout>
  )
}

export const howItWorksPageQuery = graphql`
  query HowItWorksPage($path: String!) {
    pageMarkdown: markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        title
        subtitle
        path
        description
        section {
          color
          left {
            caption
            img {
              image_file
              alt_text
            }
          }
          right {
            header_text
            bullet {
              bullet
            }
          }
        }
      }
    }
    ...cta
    ...siteTitle
  }
`
